
import Vue from "vue";
import API from "@/api/API";
import {
  emailRule,
  maxLengthRule,
  minLengthRule,
  phoneRule,
  requiredRule
} from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Reset",

  data: () => ({
    model: {} as any,
    loading: false as boolean,
    errorMessage: "" as string,
    errorMessages: {} as any,
    showPassword: {
      new: false as boolean,
      repeat: false as boolean
    } as { [value: string]: boolean }
  }),

  computed: {
    rules() {
      return {
        email: [
          (v: string): boolean | string =>
            !!v || (this.$t("auth.validation.required") as string)
        ],
        password: [
          (v: string): boolean | string =>
            (!!v && !!v.length) ||
            (this.$t("auth.validation.required") as string)
        ],
        emailRule: emailRule.bind(this),
        requiredRule: requiredRule.bind(this),
        minLengthRule: minLengthRule.bind(this),
        maxLengthRule: maxLengthRule.bind(this),
        phoneRule: phoneRule.bind(this),
        identityPassword: (v: string): any =>
          v === (this.model as any).password ||
          this.$t("auth.validation.same_password")
      };
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      await API.auth().checkToken(to.params.token);
      next();
    } catch (e) {
      next("auth/reset-expired");
    }
  },

  methods: {
    async submitForm(): Promise<void> {
      this.loading = true;
      try {
        if (await (this.$refs.form as any).validate()) {
          await this.$API
            .auth()
            .resetPassword({ ...this.model, token: this.$route.params.token });
          await this.$router.push("/auth/password-changed");
        }
      } catch (e) {
        this.errorMessage = e.message;
      }
      this.loading = false;
    },
    toggleShowPassword(type: string): void {
      this.showPassword[type] = !this.showPassword[type];
    }
  }
});
